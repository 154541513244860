import { render, staticRenderFns } from "./ReputationTriggersSettings.vue?vue&type=template&id=05edd8f0&scoped=true&"
import script from "./ReputationTriggersSettings.vue?vue&type=script&lang=ts&"
export * from "./ReputationTriggersSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05edd8f0",
  null
  
)

export default component.exports