



























































import { InputSetups } from "@/mixins/input-setups";

enum ReputationViewTabs {
  main = 'main',
  extended = 'extended',
  triggers = 'triggers',
}

import ReputationMessageHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModuleBuilder";
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import MainReputationSettings from "@/components/Modules/modules/Reputation/components/MainReputationSettings.vue";
import ExtendedReputationSettings
  from "@/components/Modules/modules/Reputation/components/ExtendedReputationSettings.vue";
import ReputationTriggersSettings
  from "@/components/Modules/modules/Reputation/components/ReputationTriggersSettings.vue";
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import CenteredColumnLayout from "piramis-base-components/src/components/CenteredColumnLayout.vue";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    SwitchInput,
    ModuleConfigSettings,
    ModuleConfigSettingsWrapper,
    ReputationTriggersSettings,
    ExtendedReputationSettings,
    MainReputationSettings,
    PiTabs,
    CenteredColumnLayout,
    PageTitle
  },
  data() {
    return {
      ReputationViewTabs
    }
  }
})
export default class ReputationMessageHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: ReputationMessageHandlerModuleBuilder

  currentView: ReputationViewTabs | null = null

  tabs: Array<SelectOptionData> = [
    {
      label: this.$t('module_reputation_message_handler_module_tab_main').toString(),
      value: ReputationViewTabs.main,
      icon: {
        name: 'settings'
      }
    },
    {
      label: this.$t('module_reputation_message_handler_module_tab_extended').toString(),
      value: ReputationViewTabs.extended,
      icon: {
        name: 'insights'
      }
    },
    {
      label: this.$t('module_reputation_message_handler_module_tab_triggers').toString(),
      value: ReputationViewTabs.triggers,
      icon: {
        name: 'bolt'
      }

    },
  ]

  updateTabParams(tab: ReputationViewTabs) {
    if (this.currentView !== tab) {
      this.$router.replace({ query: { tab } })
    }

    this.currentView = tab
  }

  created() {
    const tabQuery = this.$route.query?.tab as ReputationViewTabs | undefined

    if (tabQuery && Object.values(ReputationViewTabs).includes(tabQuery)) {
      this.currentView = tabQuery
    } else {
      this.updateTabParams(ReputationViewTabs.main)
    }
  }
}
