var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-reputation-settings"},[_c('a-alert',[_c('extended-help-message',{attrs:{"slot":"message","message":_vm.$t('reputation_page_description'),"items":{
        'more_about_reputation': _vm.ReputationCommonSettings
      }},slot:"message"})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params.positive,
          'prefix': ((_vm.module.model.snakeCaseType) + "_positive_"),
          'key': 'enabled',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),(_vm.module.model.params.positive.enabled)?_c('nested-content',[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'user_reputation_min_interval',
            'step': _vm.StepUnit.Second,
            'hasAnchor': true,
            'settingWrapper': {
              customiseValue: _vm.StepUnit.Minute,
              hasFieldButton: true,
              disabledValue: 0
            },
            'disabled': _vm.disabled
          }
        }}}),_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'chat_reputation_min_interval',
            'step': _vm.StepUnit.Second,
            'hasAnchor': true,
            'settingWrapper': {
              customiseValue: _vm.StepUnit.Minute,
              hasFieldButton: true,
              disabledValue: 0
            },
            'disabled': _vm.disabled
          }
        }}}),_c('typed-chips-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params.positive,
            'key': 'triggers',
            'prefix': ((_vm.module.model.snakeCaseType) + "_positive_"),
            'possibleChipTypeInfos': _vm.REPUTATION_TYPES,
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'positiveMessage',
            'prefix': ((_vm.module.model.snakeCaseType) + "_"),
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'targetToUpload': _vm.groupUploadTarget,
            'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'hasMedia': true,
            'mediaButtonsMessage': _vm.mediaButtonsLicenseMessage,
            'disabled': _vm.disabled
          },
        }}}),_c('a-divider'),_c('reputation-reactions',{attrs:{"prefix":"positive_","disabled":_vm.disabled},model:{value:(_vm.module.model.params.positive),callback:function ($$v) {_vm.$set(_vm.module.model.params, "positive", $$v)},expression:"module.model.params.positive"}})],1):_vm._e(),_c('a-divider'),_c('switch-input',{class:{'mt-0': !_vm.module.model.params.negative.enabled},attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params.negative,
          'key': 'enabled',
          'prefix': ((_vm.module.model.snakeCaseType) + "_negative_"),
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),(_vm.module.model.params.negative.enabled)?_c('nested-content',[_c('typed-chips-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params.negative,
            'key': 'triggers',
            'prefix': ((_vm.module.model.snakeCaseType) + "_negative_"),
            'possibleChipTypeInfos': _vm.REPUTATION_TYPES,
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'negativeMessage',
            'prefix': ((_vm.module.model.snakeCaseType) + "_"),
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'targetToUpload': _vm.groupUploadTarget,
            'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'hasMedia': true,
            'mediaButtonsMessage': _vm.mediaButtonsLicenseMessage,
            'disabled': _vm.disabled
          },
        }}}),_c('a-divider'),_c('reputation-reactions',{attrs:{"prefix":"negative_","disabled":_vm.disabled},model:{value:(_vm.module.model.params.negative),callback:function ($$v) {_vm.$set(_vm.module.model.params, "negative", $$v)},expression:"module.model.params.negative"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }